export const data = () => ({
  isDebug: true,
  isOpen: false,
  loading: true,
  isFetching: false,
  openOnFocus: true,
  miscellaneousReceipt: null,
  buttonText: 'Add',
  miscellaneousReceiptDistributionList: [],
  miscellaneousReceiptID: 0,
  selectedMiscellaneousReceiptDistribution: null,
  filters: {
    show: false
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'accountNumber',
      label: 'Account Number',
      aria: 'Account Number',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {
      field: 'amount',
      label: 'Amount',
      aria: 'Amount',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {
      field: 'description',
      label: 'Description',
      aria: 'Description',
      width: '40%',
      sortable: true,
      searchable: true
    },
    {
      field: 'dateCreated',
      date: true,

      label: 'Date Created',
      aria: 'Date Created',
      width: '20%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    formRow: {
      padding: '10px',
      whiteSpace: 'nowrap'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
