<template>
  <PageContentLayoutOnly>
    <ModernTable
      property-list-table
      :loading="loading"
      :region="region"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 10
      }"
      :filters="filters"
      :expandableRows="true"
      :rowOnClick="rowOnClick"
      :_styles="{
        tableWrapper: {
          height: 'calc(100vh - 240px)'
        }
      }"
    >
      <template v-slot:header>
        <h3 style="display: inline-block">{{ $t('units.listTitle') }}</h3>
        <div class="nowrap levels">
          <div class="subtitle level-left">Homeowners Who Owe Fees</div>
          <div class="level-right">
            <b-field label="" tabindex="0" aria-label="work order status filter">
              <b-radio
                v-model="statusFilter"
                name="name"
                native-value="all"
                aria-label="Homeowners who owe funds"
              >
                Who Owe Funds
              </b-radio>
              <b-radio
                v-model="statusFilter"
                name="name"
                native-value="assessments"
                aria-label="Homeowners who owe assessments"
              >
                Who Owe Assessments
              </b-radio>
              <b-radio
                v-model="statusFilter"
                name="name"
                native-value="nonassessments"
                aria-label="Homeowners who owe non-assessments"
              >
                Who Owe Non-Assessments
              </b-radio>
            </b-field>
          </div>
        </div>
        <div :style="{ position: 'absolute', top: 0, right: 0 }">
          <b-button
            type="is-ghost"
            @click="toggleFilters"
            :style="{
              float: 'right',
              marginTop: '4px'
            }"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon
              pack="mdi"
              icon="filter-outline"
              size="is-small"
              style="display: inline-block"
            ></b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
      </template>
    </ModernTable>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import kms from '@/services/kms'
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { watch } from './keys/watch'

export default {
  components: { PageContentLayoutOnly, ModernTable },

  props: {
    kms: {
      default() {
        return kms
      }
    }
  },

  computed,
  data,
  methods,
  watch,

  beforeMount() {
    if (this.$route.query.status) {
      this.statusFilter = this.$route.query.status
    } else {
      this.statusFilter = 'all'
    }

    this.reload()
  },

  i18n: {
    messages: {
      en: { units: { listTitle: 'Accounts Receivable' } }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

@media screen and (max-width: $tablet) {
  .filter-button {
    display: none;
  }
}

.filter-button {
  color: #838383 !important;

  &.active {
    color: $link !important;
  }
}

.button.is-ghost {
  background: none;
  border-color: transparent;
  color: #7957d5;
  text-decoration: none;
}
</style>
