var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContentLayoutOnly',[_c('ModernTable',{attrs:{"property-list-table":"","loading":_vm.loading,"region":_vm.region,"rows":_vm.rows,"columns":_vm.columns,"pagination":{
      perPage: 10
    },"filters":_vm.filters,"expandableRows":true,"rowOnClick":_vm.rowOnClick,"_styles":{
      tableWrapper: {
        height: 'calc(100vh - 240px)'
      }
    }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',{staticStyle:{"display":"inline-block"}},[_vm._v(_vm._s(_vm.$t('units.listTitle')))]),_c('div',{staticClass:"nowrap levels"},[_c('div',{staticClass:"subtitle level-left"},[_vm._v("Homeowners Who Owe Fees")]),_c('div',{staticClass:"level-right"},[_c('b-field',{attrs:{"label":"","tabindex":"0","aria-label":"work order status filter"}},[_c('b-radio',{attrs:{"name":"name","native-value":"all","aria-label":"Homeowners who owe funds"},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}},[_vm._v(" Who Owe Funds ")]),_c('b-radio',{attrs:{"name":"name","native-value":"assessments","aria-label":"Homeowners who owe assessments"},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}},[_vm._v(" Who Owe Assessments ")]),_c('b-radio',{attrs:{"name":"name","native-value":"nonassessments","aria-label":"Homeowners who owe non-assessments"},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}},[_vm._v(" Who Owe Non-Assessments ")])],1)],1)]),_c('div',{style:({ position: 'absolute', top: 0, right: 0 })},[_c('b-button',{class:'filter-button ' + (_vm.filters.show ? 'active' : ''),style:({
            float: 'right',
            marginTop: '4px'
          }),attrs:{"type":"is-ghost"},on:{"click":_vm.toggleFilters}},[_c('b-icon',{staticStyle:{"display":"inline-block"},attrs:{"pack":"mdi","icon":"filter-outline","size":"is-small"}}),_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v("Filter")])],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }