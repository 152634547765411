<template>
  <PageContentLayoutOnly headerColor="#fafafa">
    <div receiptdetails class="notification headerColor" v-if="formData">
      <div class="box is-12">
        <ValidationObserver ref="form">
          <transition name="fade" mode="out-in" appear>
            <form class="form">
              <fieldset>
                <div class="is-12 pt-5">
                  <b-field label="Transaction Date">
                    <b-datepicker
                      v-model="formData.transactionDate"
                      label="Transaction Date"
                      :show-week-number="showWeekNumber"
                      icon="calendar-today"
                      trap-focus
                      :disabled="true"
                    ></b-datepicker>
                  </b-field>
                </div>
                <div class="is-12 pt-5">
                  <b-field label="Posted Date">
                    <b-datepicker
                      v-model="formData.postedTime"
                      label="Posted Date"
                      :show-week-number="showWeekNumber"
                      icon="calendar-today"
                      trap-focus
                      :disabled="true"
                    ></b-datepicker>
                  </b-field>
                </div>
                <div class="is-12 pt-5">
                  <valid-input
                    style="width:50px !important;"
                    name="description"
                    label="Description"
                    type="textarea"
                    vid="Description"
                    placeholder="Description"
                    spellcheck="true"
                    aria-label="Description"
                    rules="max:8000"
                    class="is-small"
                    v-model="formData.description"
                    :disabled="true"
                  />
                </div>

                <div class="is-12 pt-5">
                  <valid-input
                    style="width:50px !important;"
                    name="Amount"
                    label="Amount"
                    type="text"
                    vid="Amount"
                    placeholder="Amount"
                    spellcheck="true"
                    aria-label="Amount"
                    rules="required"
                    class="is-small"
                    v-model="formData.amount"
                    :disabled="true"
                  />
                </div>
                <div class="is-6 pt-5" v-if="checkingBankAccounts">
                  <valid-select
                    label="Checking Bank Accounts"
                    placeholder="Checking Bank Accounts"
                    vid="bankAccountID"
                    required="true"
                    rules="required"
                    v-model="formData.bankAccountID"
                    :disabled="true"
                  >
                    <option
                      v-for="option in checkingBankAccounts"
                      :value="option.value"
                      :key="option.value"
                      >{{ option.label }}</option
                    >
                  </valid-select>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'

// components
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'

export default {
  name: 'ReceiptDetails',
  components: {
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider,
    PageContentLayoutOnly
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  mounted() {
    this.reload()
  },

  watch: {
    hoaId() {
      this.reload()
    }
  },

  methods,

  i18n: {
    messages: {
      en: {
        receiptDetails: { title: 'Receipt Details' }
      }
    }
  }
}
</script>

<style lang="scss">
#update-work-order .my-grid-class {
  height: 400px;
}

#update-work-order tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
