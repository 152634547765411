import $ from 'jquery'
import _get from 'lodash/get'
import { formatCurrency } from '@/filters'
import { miscellaneousReceiptsStore } from '@/services/MiscellaneousReceipts/store'
import { parseDate } from '@/utilities/Date/parse'

export const methods = {
  async reload() {
    this.loading = true
    console.debug('in reload()')

    this.miscellaneousReceiptID = this.$route.params.id

    if (this.miscellaneousReceiptID !== undefined && this.miscellaneousReceiptID) {
      this.loadDistributions()
    }

    this.loading = false
  },

  async loadDistributions() {
    await miscellaneousReceiptsStore
      .dispatch('getMiscellaneousReceiptDistributionList', {
        miscellaneousReceiptID: this.miscellaneousReceiptID
      })
      .then(({ list }) => {
        if (list) {
          this.determineResponseRows(list)
        }
      })
  },

  determineResponseRows: function(list) {
    const dateFormat = 'LL'
    var rows = list.map(entry => {
      const amount = formatCurrency(_get(entry, 'amount', 0))
      const accountNumber = _get(entry, 'debitGLAccount.accountNumber', '')
      const description = _get(entry, 'debitGLAccount.description', '')
      const dateCreated = parseDate(_get(entry, 'createdDate', null), dateFormat)

      return {
        accountNumber,
        amount,
        description,
        dateCreated: {
          date: dateCreated,
          format: dateFormat
        }
      }
    })

    this.rows = rows
  },

  open() {
    this.isOpen = true
    this.loading = false
  },

  closeModal() {
    this.$emit('update:toggle', (this.documentToggle = !this.documentToggle))

    // auto-close modal
    $('#clickclose').click()
  }
}
