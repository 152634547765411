import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
import _isPlainObject from 'lodash/isPlainObject'
import parseAddress from '@/utilities/address/parse'
import { notifyProblem } from '@/services/notify'
import Details from './../components/Details'
import SimpleButton from '@/components/buttons/Simple'

export const methods = {
  parseRows({ units = null } = {}) {
    if (!_isPlainObject(units)) {
      units = this.units
    }

    this.rows = units.map(unit => {
      const parsedAddress = parseAddress({
        address: _get(unit, 'propertyAddress', null)
      })

      let tempFees = _get(unit, ['chargeBalances'], []).map(m => ({
        ...m,
        overallBalance: unit.overallBalance,
        recurringBalance: unit.recurringBalance,
        nonRecurringBalance: unit.nonRecurringBalance
      }))

      const totalBalance = _get(unit, 'overallBalance', 0)
      const unitID = _get(unit, 'unitID', 0)
      const fromOwnerID = _get(unit, 'ownerID', 0)

      const redirectPath =
        '/properties/' +
        unitID +
        '/' +
        fromOwnerID +
        '/' +
        fromOwnerID +
        '/accountsReceivable?fromOwnerID=' +
        fromOwnerID +
        '#tab'

      return {
        _expandable: {
          // expanded: false,

          // whether or not to show the expandable icon
          show: true,

          // the component to show when the expandable icon is pressed
          component: Details,

          // props to send to the expandable icon
          props: {
            ownerFees: tempFees
          }
        },
        lotNumber: _get(unit, 'lotNumber', ''),
        name: _get(unit, 'ownerName', ''),
        address: parsedAddress,
        overallBalance: Number.parseFloat(totalBalance).toFixed(2),
        button: {
          component: SimpleButton,
          props: {
            to: {
              path: redirectPath
            },
            text: 'View Account',
            styles: {
              marginBottom: '5px'
            }
          }
        }
      }
    })
  },

  async loadUnits() {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    try {
      const results = await this.kms.get('/AccountsReceivable/ARStatistics/GetCurrentDetail', {
        params: {
          hoaID
        }
      })

      let units = []

      if (results && results.ownersOwingAnyFunds && results.ownersOwingAnyFunds != undefined) {
        if (this.statusFilter === 'assessments') {
          units = results.ownersOwingRecurring
        } else if (this.statusFilter === 'nonassessments') {
          units = results.ownersOwingNonRecurring
        } else {
          units = results.ownersOwingAnyFunds
        }
      }

      console.debug('unitStats=' + JSON.stringify(units))

      this.allUnits = _cloneDeep(units)
      this.units = units

      this.parseRows()
    } catch (e) {
      notifyProblem(e)
    }
  },

  rowOnClick() {},

  searchByOther({ clear = false } = {}) {
    if (clear === false) {
      this.searchedByOther = true
    }

    this.parseRows()

    this.showSearchOptions = false
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  async reload() {
    this.loading = true
    await this.loadUnits()
    this.loading = false
  }
}
