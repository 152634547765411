/*
  import { parseFees } from '@/services/Roster/Owner/parseFees'
*/

import _get from 'lodash/get'

export function parseFees({ fees }) {
  return fees.map(fee => {
    return {
      chargeTypeName: _get(fee, 'chargeTypeName', null),
      isRecurring: _get(fee, 'isRecurring', false),
      balance: _get(fee, 'balance', 0),
      overallBalance: _get(fee, 'overallBalance', 0),
      recurringBalance: _get(fee, 'recurringBalance', 0),
      nonRecurringBalance: _get(fee, 'nonRecurringBalance', 0)
    }
  })
}
