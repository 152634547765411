export const methods = {
  async reload() {
    this.loading = true

    this.miscellaneousReceiptID = this.$route.params.id || 0

    this.loading = false
  },

  returnToParent() {
    this.$router.push({
      name: 'ar-miscellaneousreceipts'
    })
  }
}
