var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContentLayoutOnly',{attrs:{"role":"region","miscellaneous-receipt-details":""}},[_c('section',{staticClass:"section card"},[_c('div',{staticClass:"level pl-5 pr-5",style:(Object.assign({}, {display: 'flex',
        alignItems: 'center'},
        (_vm.breakpoint === 'desktop'
          ? {}
          : {
              flexDirection: 'column'
            })))},[_c('div',{staticClass:"pt-5 level-left level-item title"},[_vm._v(_vm._s(_vm.$t('receiptsDetail.title')))]),_c('div',{style:({ display: 'flex', alignItems: 'center' })},[_c('svg',{attrs:{"width":"30","height":"30","xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 19l-7-7 7-7"}})]),_c('b-button',{attrs:{"label":"Back to Miscellaneous Receipts"},on:{"click":function($event){$event.preventDefault();return _vm.returnToParent()}}})],1)]),_c('LinksMenu',{style:({
        paddingLeft: '20px'
      }),attrs:{"aria-label":"miscellaneous receipt detail pages","tabs":_vm.tabs,"prefix":"receipt.","params":{
        id: _vm.$route.params.id
      }}},[_c('section',{style:({ overflowX: 'auto', flexGrow: 2, marginTop: '-20px' })},[_c('SlideTransition',{attrs:{"name":_vm.transitionName}},[_c('keep-alive',[_c('router-view',{key:_vm.$route.name})],1)],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }