<template>
  <div>
    <h4 :style="{ margin: '0 10px' }">Fee Details</h4>
    <OwnerFees :fees="fees" />
  </div>
</template>

<script>
import _get from 'lodash/get'
import OwnerFees from './OwnerFees'
import { parseFees } from '@/services/Roster/Owner/parseFees'

export default {
  components: {
    OwnerFees
  },
  props: {
    ownerFees: Array
  },
  data() {
    const theme = _get(this, ['$store', 'state', 'theme'], {})

    return {
      fees: parseFees({
        fees: this.ownerFees
      }),
      color: theme.color
    }
  },
  watch: {
    ownerFees() {
      this.fees = parseFees({
        fees: this.ownerFees
      })
    }
  }
}
</script>
