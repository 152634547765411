export const data = function() {
  return {
    isDebug: true,
    loading: false,
    miscellaneousReceiptID: 0,
    miscellaneousReceipt: null,
    tabs: [
      { name: 'receiptDetails', label: 'Receipt Detail' },
      { name: 'receiptDistributions', label: 'Distributions' }
    ],
    styles: {
      mainContainer: {
        minHeight: '1000px'
      }
    },
    transitionName: 'slide-down',
    showUploadModal: false
  }
}
