import _get from 'lodash/get'
import { notifyProblem } from '@/services/notify'
import SimpleButton from '@/components/buttons/Simple'
import { miscellaneousReceiptsStore } from '@/services/MiscellaneousReceipts/store'
import { parseDate } from '@/utilities/Date/parse'
import { formatCurrency } from '@/filters'

export const methods = {
  parseRows() {
    this.rows = this.miscellaneousReceipts.map(receipt => {
      const dateFormat = 'LL'
      const tempDateCompleted = _get(receipt, 'transactionDate', null)
      const transactionDate = tempDateCompleted ? parseDate(tempDateCompleted, dateFormat) : null
      const tempDateCompletedTwo = _get(receipt, 'postedTime', null)
      const postedDate = tempDateCompletedTwo ? parseDate(tempDateCompletedTwo, dateFormat) : null
      const miscellaneousReceiptID = _get(receipt, 'miscellaneousReceiptID', 0)
      const description = _get(receipt, 'description', '')
      const amount = formatCurrency(_get(receipt, 'amount', 0))
      const bankAccountName = _get(receipt, 'bankAccount.name', '')

      return {
        description,
        amount,
        bankAccountName,
        transactionDate: {
          date: transactionDate,
          format: dateFormat
        },
        postedDate: {
          date: postedDate,
          format: dateFormat
        },
        button: {
          component: SimpleButton,
          props: {
            to: {
              path: `/receipttabs/${miscellaneousReceiptID}/receiptDetails#tab`,
              params: {
                id: miscellaneousReceiptID
              }
            },
            text: 'View Receipt',
            styles: {
              marginBottom: '5px'
            }
          }
        }
      }
    })
  },

  async loadMiscellaneousReceipts() {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    try {
      await miscellaneousReceiptsStore
        .dispatch('getMiscellaneousReceiptList', {
          hoaID
        })
        .then(({ list }) => {
          if (list) {
            this.miscellaneousReceipts = list
          }
        })

      this.parseRows()
    } catch (e) {
      notifyProblem(e)
    }
  },

  rowOnClick() {},

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  async reload() {
    this.loading = true
    await this.loadMiscellaneousReceipts()
    this.loading = false
  }
}
