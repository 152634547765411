var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"owner-fees":""}},[_vm._l((_vm.list),function(fee,indexA){return _c('div',{key:indexA,style:(Object.assign({}, {justifyContent: 'space-between'},
      (indexA !== _vm.list.length - 1 ? { marginBottom: '6px' } : {}),
      {padding: '0px 10px'})),attrs:{"owner-fee":""}},[_c('div',{style:({ flexBasis: '33%' })},[(indexA == 0)?_c('div',{staticClass:"pt-5",style:({ color: _vm.color[8], fontWeight: 'bold' })},[_vm._v(" Non-Assessment (Non-Recurring) ")]):_vm._e(),(
          fee.chargeTypeName &&
            typeof fee.chargeTypeName === 'string' &&
            fee.chargeTypeName.length >= 1 &&
            fee.isRecurring == false
        )?_c('div',{staticClass:"columns",style:({ color: _vm.color[8], height: '27px' }),attrs:{"owner-name":""}},[_c('div',{staticClass:"column is-6"},[_vm._v(_vm._s(fee.chargeTypeName))]),_c('div',{staticClass:"column is-2 has-text-right"},[_vm._v(_vm._s(_vm._f("currency")(fee.balance)))])]):_c('div',{style:({ color: _vm.color[8] })},[_vm._v(" N/A ")]),(
          fee.chargeTypeName &&
            typeof fee.chargeTypeName === 'string' &&
            fee.chargeTypeName.length >= 1 &&
            fee.isRecurring == false &&
            indexA == _vm.list.length - 1
        )?_c('div',{staticClass:"columns",style:({ color: _vm.color[8] }),attrs:{"owner-name":""}},[_c('div',{staticClass:"column is-6"},[_vm._v("Total")]),_c('div',{staticClass:"column is-2 has-text-right font-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(fee.nonRecurringBalance))+" ")])]):_vm._e()]),_c('div',{style:({ flexBasis: '33%' })},[(indexA == _vm.list.length - 1)?_c('div',{staticClass:"pt-5",style:({ color: _vm.color[8], fontWeight: 'bold' })},[_vm._v(" Assessment (Recurring) ")]):_vm._e(),(
          fee.chargeTypeName &&
            typeof fee.chargeTypeName === 'string' &&
            fee.chargeTypeName.length >= 1 &&
            fee.isRecurring == true
        )?_c('div',{staticClass:"columns",style:({ color: _vm.color[8] }),attrs:{"owner-name":""}},[_c('div',{staticClass:"column is-6"},[_vm._v(_vm._s(fee.chargeTypeName))]),_c('div',{staticClass:"column is-2 has-text-right"},[_vm._v(_vm._s(_vm._f("currency")(fee.balance)))])]):(indexA == _vm.list.length - 1)?_c('div',{style:({ color: _vm.color[8] })},[_vm._v(" N/A ")]):_vm._e()]),_c('div',{style:({ flexBasis: '33%' })},[(indexA == _vm.list.length - 1)?_c('div',{staticClass:"pt-5",style:({ color: _vm.color[8], fontWeight: 'bold' })},[_vm._v(" All Fees ")]):_vm._e(),(
          indexA == _vm.list.length - 1 &&
            fee.overallBalance &&
            typeof fee.overallBalance === 'number'
        )?_c('div',{staticClass:"columns",style:({ color: _vm.color[8] }),attrs:{"owner-name":""}},[_c('div',{staticClass:"column is-6"},[_vm._v("Total")]),_c('div',{staticClass:"column is-2 has-text-right font-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(fee.overallBalance))+" ")])]):(indexA == _vm.list.length - 1)?_c('div',{style:({ color: _vm.color[8] })},[_vm._v(" N/A ")]):_vm._e()])])}),(!Array.isArray(_vm.list) || _vm.list.length === 0)?_c('div',{style:({
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '6px',
      padding: '8px 12px',
      borderRadius: '6px',
      color: _vm.color[8]
    }),attrs:{"fee-details-not-found":""}},[_vm._v(" Assessment and Non-Assessment Fee details were not found. ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }