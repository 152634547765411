<template>
  <PageContent>
    <div>
      <ModernTable
        :region="region"
        :filters="filters"
        :rows="rows"
        :columns="columns"
        :pagination="{
          perPage: 12
        }"
        :loading="loading"
      />
    </div>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import ModernTable from '@/components/tables/Modern/Table'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  name: 'MiscellaneousReceiptDistributions',
  components: {
    PageContent,
    ModernTable
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser']),
      breakpoint: state => (state && state.win ? state.win.breakpoint : null)
    })
  },

  created() {},

  mounted() {
    this.reload()
  },

  watch: {
    hoaId() {
      this.reload()
    }
  },

  methods,

  i18n: {
    messages: {
      en: {
        distributions: { title: 'Distributions' }
      }
    }
  }
}
</script>
