<template>
  <div owner-fees>
    <div
      owner-fee
      v-for="(fee, indexA) in list"
      :key="indexA"
      :style="{
        justifyContent: 'space-between',
        ...(indexA !== list.length - 1 ? { marginBottom: '6px' } : {}),
        padding: '0px 10px'
      }"
    >
      <div :style="{ flexBasis: '33%' }">
        <div class="pt-5" v-if="indexA == 0" :style="{ color: color[8], fontWeight: 'bold' }">
          Non-Assessment (Non-Recurring)
        </div>
        <div
          class="columns"
          owner-name
          :style="{ color: color[8], height: '27px' }"
          v-if="
            fee.chargeTypeName &&
              typeof fee.chargeTypeName === 'string' &&
              fee.chargeTypeName.length >= 1 &&
              fee.isRecurring == false
          "
        >
          <div class="column is-6">{{ fee.chargeTypeName }}</div>
          <div class="column is-2 has-text-right">{{ fee.balance | currency }}</div>
        </div>
        <div v-else :style="{ color: color[8] }">
          N/A
        </div>
        <div
          class="columns"
          owner-name
          :style="{ color: color[8] }"
          v-if="
            fee.chargeTypeName &&
              typeof fee.chargeTypeName === 'string' &&
              fee.chargeTypeName.length >= 1 &&
              fee.isRecurring == false &&
              indexA == list.length - 1
          "
        >
          <div class="column is-6">Total</div>
          <div class="column is-2 has-text-right font-bold">
            {{ fee.nonRecurringBalance | currency }}
          </div>
        </div>
      </div>
      <div :style="{ flexBasis: '33%' }">
        <div
          class="pt-5"
          v-if="indexA == list.length - 1"
          :style="{ color: color[8], fontWeight: 'bold' }"
        >
          Assessment (Recurring)
        </div>
        <div
          class="columns"
          owner-name
          :style="{ color: color[8] }"
          v-if="
            fee.chargeTypeName &&
              typeof fee.chargeTypeName === 'string' &&
              fee.chargeTypeName.length >= 1 &&
              fee.isRecurring == true
          "
        >
          <div class="column is-6">{{ fee.chargeTypeName }}</div>
          <div class="column is-2 has-text-right">{{ fee.balance | currency }}</div>
        </div>
        <div v-else-if="indexA == list.length - 1" :style="{ color: color[8] }">
          N/A
        </div>
      </div>
      <div :style="{ flexBasis: '33%' }">
        <div
          class="pt-5"
          v-if="indexA == list.length - 1"
          :style="{ color: color[8], fontWeight: 'bold' }"
        >
          All Fees
        </div>
        <div
          class="columns"
          owner-name
          :style="{ color: color[8] }"
          v-if="
            indexA == list.length - 1 &&
              fee.overallBalance &&
              typeof fee.overallBalance === 'number'
          "
        >
          <div class="column is-6">Total</div>
          <div class="column is-2 has-text-right font-bold">
            {{ fee.overallBalance | currency }}
          </div>
        </div>
        <div v-else-if="indexA == list.length - 1" :style="{ color: color[8] }">
          N/A
        </div>
      </div>
    </div>

    <div
      fee-details-not-found
      v-if="!Array.isArray(list) || list.length === 0"
      :style="{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '6px',
        padding: '8px 12px',
        borderRadius: '6px',
        color: color[8]
      }"
    >
      Assessment and Non-Assessment Fee details were not found.
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get'

/*
  <OwnerFees :fees="fees" />
*/
export default {
  props: {
    fees: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    const theme = _get(this, ['$store', 'state', 'theme'], {})

    return {
      color: theme.color,
      list: this.fees
    }
  },
  mounted() {
    this.processList()
  },
  methods: {
    processList() {
      let filtered = []
      if (this.fees) {
        Array.from(this.fees).forEach(f => {
          if (f) {
            if (f.chargeTypeName == 'Assessment') {
              filtered.push(f)
            }
          }
        })
        Array.from(this.fees).forEach(f => {
          if (f) {
            if (f.chargeTypeName != 'Assessment') {
              filtered.push(f)
            }
          }
        })

        console.log('filtered=' + JSON.stringify(filtered))

        this.list = filtered
      } else {
        this.list = this.fees
      }
    }
  },
  watch: {
    fees() {
      this.processList()
    }
  }
}
</script>
