export function data() {
  return {
    emails: [],
    emailKeys: {},
    phoneNumbers: [],
    rows: [],
    units: [],
    showSearchOptions: false,
    activeTab: 0,
    emailFilter: '',
    phoneNumberFilter: '',
    shownEmailFilter: '',
    shownPhoneNumberFilter: '',
    statusFilter: 'all',

    region: {
      title: this.$t('units.listTitle'),
      role: 'main',
      ariaLabel: this.$t('units.listTitle')
    },

    loading: true,
    selectedRow: null,

    searchedByOther: false,

    filters: {
      show: true
    },

    columns: [
      {
        field: 'lotNumber',
        label: 'Lot #',
        aria: 'Lot number',
        width: '15%',
        numeric: true,
        sortable: true,
        searchable: true
      },
      {
        field: 'name',
        label: 'Name',
        width: '35%',
        sortable: true,
        searchable: true
      },
      {
        field: 'address',
        label: 'Address',
        width: '35%',
        sortable: true,
        searchable: true
      },
      {
        field: 'overallBalance',
        label: 'Total Fees',
        aria: 'Total Fees',
        width: '15%',
        numeric: true,
        sortable: true,
        searchable: true
      },
      {
        prefix: '$'
      }
    ]
  }
}
