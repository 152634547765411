import { mapState } from 'vuex'
import _get from 'lodash/get'

export const computed = {
  ...mapState({
    hoaId: state => state.user.selectedHoaId,
    unit: state => state.hoa.unit,
    ownerID() {
      return _get(this, ['unit', 'primaryOwner', 'ownerID'], null)
    }
  })
}
