import { miscellaneousReceiptsStore } from '@/services/MiscellaneousReceipts/store'
import { listBankAccounts } from '@/services/BankAccounts/BankAccount/List'
import { notifyError } from '@/services/notify'

var DEFAULT_FORM_VALUES = {
  miscellaneousReceiptID: 0,
  description: '',
  amount: 0,
  transactionDate: null,
  postedTime: null,
  bankAccountID: 0
}

export const methods = {
  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  async reload() {
    this.loading = true

    this.formData = { ...DEFAULT_FORM_VALUES }

    this.miscellaneousReceiptID = this.$route.params.id

    await miscellaneousReceiptsStore
      .dispatch('getMiscellaneousReceiptById', {
        miscellaneousReceiptID: this.miscellaneousReceiptID
      })
      .then(({ result }) => {
        if (result) {
          this.miscellaneousReceipt = result
          this.formData.transactionDate = new Date(result.transactionDate)
          this.formData.postedTime = result.postedTime ? new Date(result.postedTime) : null
          this.formData.description = result.description
          this.formData.amount = result.amount
          this.formData.bankAccountID = result.bankAccountID
        }
      })

    if (this.isDebug == true)
      console.debug('miscellaneousReceipt=' + JSON.stringify(this.miscellaneousReceipt))

    this.getBankAccounts()

    this.loading = false
  },

  async getBankAccounts() {
    const { successful, message, result } = await listBankAccounts({
      params: {
        hoaID: this.hoaId,
        hasCheckingOnly: true
      }
    })
    if (!successful) {
      notifyError(message)
      throw new Error(message)
    }

    var tempBankAccounts = result.results
    const checkingBankAccounts = tempBankAccounts.map(({ bankAccountID: value, name: label }) => ({
      value,
      label
    }))

    console.debug('checking bank accounts=' + JSON.stringify(checkingBankAccounts))

    this.checkingBankAccounts = checkingBankAccounts
  },

  returnToParent() {
    this.$router.push({
      name: 'ar-miscellaneousReceipts'
    })
  }
}
