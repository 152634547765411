const DEFAULT_FORM_VALUES = Object.freeze({
  transactionDate: null,
  description: '',
  amount: 0,
  bankName: ''
})

const data = function() {
  return {
    rows: [],
    miscellaneousReceipts: [],
    showSearchOptions: false,
    activeTab: 0,
    formComplete: false,
    formData: {
      ...DEFAULT_FORM_VALUES
    },
    loading: false,
    isDebug: true,
    isFetching: false,
    selectedRow: null,

    filters: {
      show: true
    },

    region: {
      title: this.$t('miscReceipts.title'),
      role: 'main',
      ariaLabel: this.$t('miscReceipts.title')
    },

    columns: [
      {
        field: 'description',
        label: 'Description',
        width: '45%',
        sortable: true,
        searchable: true
      },
      {
        field: 'amount',
        label: 'Amount',
        width: '10%',
        numeric: true,
        sortable: true,
        searchable: true
      },
      {
        field: 'bankAccount',
        label: 'Bank Account',
        aria: 'Bank Account',
        width: '35%',
        sortable: true,
        searchable: true
      },
      {
        field: 'transactionDate',
        label: 'Transaction Date',
        aria: 'Transaction Date',
        width: '20%',
        date: true,
        sortable: true,
        searchable: true
      },
      {
        field: 'postedDate',
        label: 'Posted Date',
        aria: 'Posted Date',
        width: '20%',
        date: true,
        sortable: true,
        searchable: true
      }
    ]
  }
}

export { DEFAULT_FORM_VALUES, data }
