<template>
  <PageContentLayoutOnly>
    <ModernTable
      receipts-list-table
      :loading="loading"
      :rows="rows"
      :region="region"
      :columns="columns"
      :pagination="{
        perPage: 10
      }"
    >
      <template v-slot:header>
        <h3 style="display: inline-block; margin-left: -15px;">{{ $t('miscReceipts.title') }}</h3>
        <div :style="{ position: 'absolute', top: 0, right: 0 }">
          <b-button
            type="is-ghost"
            @click="toggleFilters"
            :style="{
              float: 'right',
              marginTop: '4px'
            }"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon
              pack="mdi"
              icon="filter-outline"
              size="is-small"
              style="display: inline-block"
            ></b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
      </template>
    </ModernTable>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { watch } from './keys/watch'

export default {
  components: {
    PageContentLayoutOnly,
    ModernTable
  },

  computed,
  data,
  methods,
  watch,

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { miscReceipts: { title: 'Miscellaneous Receipts' } }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';
@media screen and (max-width: $tablet) {
  .filter-button {
    display: none;
  }
}

.filter-button {
  color: #838383 !important;

  &.active {
    color: $link !important;
  }
}

.button.is-ghost {
  background: none;
  border-color: transparent;
  color: #7957d5;
  text-decoration: none;
}
</style>
